import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { EMPTY, NEVER, Observable, ReplaySubject, throwError } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import {
  CoreViewsCommandRefreshView,
  IVboOperation,
  IViewMode, ViewExecutableCountResult,
  ViewResult,
  ViewsPagerUserConfigurationSimple,
  ViewsPluginRequest,
  ViewsQuickSearchUserConfiguration,
  ViewsVboSelectedItem,
  ViewUserConfiguration,
  WebServiceResponse
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { backendTypeMatch, getInSafe, jsonEqual } from '../utils/typescript.utils';
import { LocalActionEventData2 } from './events/localaction.eventdata';
import { SingleItemOperationEventData2 } from './events/singleitemoperation.eventdata';
import { ViewsinitializedEventdata } from './events/viewsinitialized.eventdata';
import { ViewModeUtils } from './grid/viewmode.utils';
import { ListComponent2Service } from './list.service';
import { ViewsuserconfigchangedAction, ViewsuserconfigchangedEventdata } from './viewsuserconfigchanged.eventdata';
import { IViewComponent } from '../custom-component-factory/components/i-view-component';
import { catchError, delay, filter, map, takeUntil } from 'rxjs/operators';
import { SioFormOpenedEventData } from './events/sioformopened.eventdata';
import { CommandService } from '../../core/commands/command.service';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { VboOperations, VboToggleEvent } from './events/vboitemtoogle.eventdata';
import { DynamicComponent } from '../custom-component-factory/type-manager.decorator';
import { ViewsService } from '../../core/services/ETG_SABENTISpro_Application_Core_views.service';
import { CORE_QUEUE } from '../../core/models/ETG_SABENTISpro_Models_models';
import { MessageToastService } from '../../core/message-toast/share/message-toast.service';

/**
 * Generic List component
 * With this component we can generate all list based in a configuration
 *
 * This component uses next childs components:
 *  1) Componet for menu bar options
 *  2) Component to show data (mode: table, list or html)
 *  3) Component to paginate data
 */

@DynamicComponent('View')
@Component({
  selector: 'app-list2',
  templateUrl: './list.component.html',
  providers: [ListComponent2Service, ViewsService],
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class List2Component extends DestroyableObjectTrait implements OnChanges, OnDestroy, OnInit, IViewComponent {

  /**
   * Plugin identifier for the list.
   */
  @Input() listId: string;

  /**
   * The title of the list
   */
  @Input() title: string = null;

  /**
   * Hide the component if the user does not have permissions to load the view
   */
  @Input() hideOn403: boolean = true;

  /**
   * Si usamos carga explícita, el listado debe inicializarse programáticamente
   * utilizando el método Load(ViewsPluginRequest) y los parámetros "args" y "listID"
   * serán ignorados.
   */
  @Input() explicitLoad: boolean = false;

  /**
   * Arguments to pass on the list query.
   */
  @Input() args: any[];

  /**
   * Selected rows object.
   */
  @Input() selectedRows: { [id: string]: ViewsVboSelectedItem; } = {};

  /**
   * Local actions event emmiter.
   */
  @Output() onLocalAction: EventEmitter<LocalActionEventData2>;

  /**
   * Single items operations event emitter.
   */
  @Output() onSingleItemOperation: EventEmitter<SingleItemOperationEventData2>;

  /**
   * VBO event emitter.
   */
  @Output() vboSelectedItems = new EventEmitter<{ [id: string]: ViewsVboSelectedItem }>();

  /**
   * VBO event emitter.
   */
  @Output() viewIntialized = new ReplaySubject<ViewsinitializedEventdata>();

  /**
   *
   */
  @Output() onSioFormOpened = new EventEmitter<SioFormOpenedEventData>();

  /**
   * Triggered when a Single Item Operation happens
   */
  @Output() onVboOperationCompleted: EventEmitter<{ operation: IVboOperation, result: any }> =
      new EventEmitter<{
        operation: IVboOperation,
        result: { id: string, message: any[], responseData: any } | CORE_QUEUE
      }>();

  /**
   * Si hay un error de permisos para acceder, se usa para evitar que se muestre el listado.
   */
  authError: boolean = false;

  /**
   * The list configuration.
   */
  configuration: ViewUserConfiguration = null;

  /**
   * Animación de carga inicial
   */
  loadingAnimationPlaceholder?: boolean = null;

  /**
   * Si debo mostrar el mensaje de que no hay resultados, y que reemplaza completamente al componente
   */
  emptyResultSetMessage: boolean = null;

  /**
   * View execution result
   */
  data: ViewResult;

  /**
   * The current plugin rquest
   */
  currentPluginRequest: ViewsPluginRequest;

  /**
   * If the load fails, the http error code
   */
  loadErrorCode: number;

  showLoadError: boolean = false;

  /**
   * Class constructor
   *
   * @param {ListComponent2Service} listComponentService The core orquestrator service.
   */
  constructor(
      public listComponentService: ListComponent2Service,
      private cdRef: ChangeDetectorRef,
      private commandService: CommandService,
      private messageToastService: MessageToastService,
  ) {
    super();
    this.onLocalAction = this.listComponentService.onLocalAction;
    this.onSingleItemOperation = this.listComponentService.onSingleItemOperation;
    this.onVboOperationCompleted = this.listComponentService.onVboOperationCompleted;
    this.vboSelectedItems = this.listComponentService.vboSelectedRowsChanged;
    this.viewIntialized = this.listComponentService.viewIntialized;
    this.listComponentService.cdRef = this.cdRef;
    this.cdRef.detach();
  }

  get doShowLoading(): boolean {
    return this.loadingAnimationPlaceholder;
  }

  ngOnInit(): void {

    this.listComponentService
        .onSioFormOpened
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((i) => {
              this.onSioFormOpened.next(i);
            }
        );

    // Cuando cambie la configuración de usuario, recargar el listado
    this.listComponentService
        .userConfigurationChanged
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((next) => {
          if (next.refreshAction === ViewsuserconfigchangedAction.Ignore) {
            return;
          }
          if (this.listComponentService.getConfiguration()
              && this.listComponentService.getUserConfiguration()) {
            this.loadList(false, next);
          }
        });

    this.listComponentService
        .viewIntialized
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((next) => {
          if (this.title === undefined || this.title === null) {
            this.title = next.configuration.Title;
          }
        });

    this.listComponentService
        .viewDataLoaded
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((next: ViewResult) => {
          this.emptyResultSetMessage = this.listComponentService.emptyResultSetRender;
          // TODO? Hay que hacer aquí un detectChanges?
        });

    // Register commands
    this.registerCommands(this.commandService);
  }

  /**
   *
   * @param commandService
   */
  private registerCommands(commandService: CommandService): void {

    // Comando para refrescar el formulario (refrescar no es recargar!)
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreViewsCommandRefreshView.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreViewsCommandRefreshView, (() => Promise<boolean>) | Observable<boolean>>),
            filter(obj => this.currentPluginRequest && !!this.currentPluginRequest.Id.match(obj.Argument.ViewsIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        if (next.Argument.ClearSelectedVboItems) {
          this.clearSelectedVboIfExists();
        }
        this.refresh();
        return Promise.resolve(true);
      });
    });
  }

  /**
   * Lifecycle hook that is called when any data-bound property of a directive changes.
   *
   * @param {SimpleChanges} changes Changes object collector
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (this.explicitLoad === true) {
      return;
    }

    // If a list identifier or arguments changes.
    if (
        (changes['listId'] && (!jsonEqual(changes['listId'].previousValue, changes['listId'].currentValue)) ||
            (changes['args'] && (!jsonEqual(changes['args'].previousValue, changes['args'].currentValue))))) {

      // Reset load animation
      this.loadingAnimationPlaceholder = true;
      this.emptyResultSetMessage = false;

      // Clear user configuration.
      this.listComponentService.setUserConfiguration(null, false);

      this.currentPluginRequest = new ViewsPluginRequest();
      this.currentPluginRequest.Arguments = this.args;
      this.currentPluginRequest.Id = this.listId;

      // Try loading the list again.
      this.loadList(true);
    }

    // If the input for selected rows changes then update the service array.
    if (changes['selectedRows']
        && (changes['selectedRows'].previousValue !== changes['selectedRows'].currentValue)) {
      this.listComponentService.vboUserConfiguration.SelectedItems =
          !isNullOrUndefined(this.selectedRows) ? this.selectedRows : {};
    }
  }

  resetAndLoadList(): void {
     // Reset load animation
    this.loadingAnimationPlaceholder = true;
    this.emptyResultSetMessage = false;

    // Clear user configuration.
    this.listComponentService.setUserConfiguration(null, false);

    this.currentPluginRequest = new ViewsPluginRequest();
    this.currentPluginRequest.Arguments = this.args;
    this.currentPluginRequest.Id = this.listId;

    // Try loading the list again.
    this.loadList(true);
  }

  /**
   * Refresca el result-set del listado, sin modificar la configuración de usuario
   */
  refresh(): void {
    // La manera de refrescar es hacer un reseteo de la configuración de usuario
    this.listComponentService.setUserConfiguration(this.listComponentService.getUserConfiguration());
  }

  clearSelectedVboIfExists(): void {
    const event: VboToggleEvent = new VboToggleEvent()
    event.operation = VboOperations.CLEAR;
    this.listComponentService.vboToggleItemHandler(event);
  }

  /**
   * Fires when clicking on search button inside list-menu-bar-component.
   * @param {string} searchString
   */
  onSearchChange(searchString: string): void {

    // Get the latest configuration.
    const configuration: ViewUserConfiguration =
        this.listComponentService.getUserConfiguration();

    // Clean the search string.
    if (isNullOrUndefined(configuration.Search)) {
      configuration.Search = new ViewsQuickSearchUserConfiguration();
    }
    configuration.Search.SearchString = searchString;

    // Sear the current page to index 0.
    if (!isNullOrUndefined(configuration.Pagination)) {
      (configuration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage = 0;
    }

    // Set the new configuration.
    this.listComponentService.setUserConfiguration(configuration);
  }

  /**
   * Returns an array with the classes for the list container.
   *
   * This classes are used by another services like Google Service Tag Manager.
   */
  getClasses(): string[] {
    const config: IViewMode = ViewModeUtils.GetCurrentViewModeFromService(this.listComponentService);
    if (isNullOrUndefined(config)) {
      return [];
    }
    const classes: string[] = config.CssClasses;
    if (!isNullOrUndefined(this.currentPluginRequest)) {
      classes.push(`list-${this.currentPluginRequest.Id}`);
    }
    return classes;
  }

  /**
   * Carga el listado utilizando la información que lleva vía pluginRequest
   *
   * @param {boolean} init Sets if the list configurations must be updated.
   * @param {ViewsuserconfigchangedEventdata} options Indicates to the service if custom options must be used.
   */
  loadListFromPluginRequest<T>(
      pluginRequest: ViewsPluginRequest,
      init: boolean,
      options: ViewsuserconfigchangedEventdata = null): void {
    // Si es la primera carga (animationplaceholder===null) o estamos con un mensaje de no hay resultados,
    // cargamos la animación
    if (this.loadingAnimationPlaceholder === null || this.emptyResultSetMessage === true) {
      this.loadingAnimationPlaceholder = true;
    }
    this.currentPluginRequest = pluginRequest;
    this.listComponentService
        .loadList(pluginRequest, init, options)
        .pipe(
            takeUntil(this.componentDestroyed$),
            catchError((i) => {
                  if (i instanceof HttpErrorResponse) {
                    this.loadErrorCode = i.status;
                    switch (i.status) {
                      case 403:
                      case 401:
                        // Acceso denegado....
                        if (this.hideOn403 === true) {
                          const wsResponse403: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                          console.warn(wsResponse403?.error?.message);
                          this.authError = true;
                          this.loadingAnimationPlaceholder = false;
                          this.cdRef.detectChanges();
                          this.showLoadError = true;
                          return NEVER;
                        }
                        break;
                      case 0:
                      case 429:
                        const wsResponse429: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                        console.warn(wsResponse429?.error?.message);
                        this.authError = true;
                        this.loadingAnimationPlaceholder = false;
                        this.showLoadError = true;
                        this.cdRef.detectChanges();
                        return NEVER;
                      case 423:
                        if (this.listComponentService.getConfiguration() === null || this.listComponentService.getConfiguration() === undefined) {
                          this.showLoadError = true;
                          this.authError = false;
                          this.loadingAnimationPlaceholder = false;
                          this.listComponentService.requestInProgress$.next(false);
                          this.cdRef.detectChanges();
                        } else {
                          this.messageToastService.showWarning('Se ha sobrepasado el tiempo de espera para la carga de información. Refresque el listado o inténtelo de nuevo más tarde.', 'Error al cargar', false);
                          this.authError = false;
                          this.loadingAnimationPlaceholder = false;
                          this.listComponentService.requestInProgress$.next(false);
                          this.cdRef.detectChanges();
                          this.showLoadError = false;
                        }
                        return NEVER;
                    }
                  }
                  return throwError(i);
                }
            )
        )
        .subscribe((response: ViewResult) => {
          if (response !== null) {
            this.showLoadError = false;
            this.authError = false;
            this.data = this.listComponentService.data;
            this.listComponentService.detectChanges();
            if (this.loadingAnimationPlaceholder === true) {
              this.loadingAnimationPlaceholder = false;
            }
          }
          this.cdRef.detectChanges();
        });
  }

  /**
   * Carga el listado utilizando la información que hay en this.listId y this.args
   *
   * @param {boolean} init Sets if the list configurations must be updated.
   * @param {ViewsuserconfigchangedEventdata} options Indicates to the service if custom options must be used.
   */
  loadList(init: boolean, options: ViewsuserconfigchangedEventdata = null): void {
    this.loadListFromPluginRequest(this.currentPluginRequest, init, options);
  }

  /**
   * Loads a list and optionally update the list configuration.
   * @param {boolean} init Sets if the list configurations must be updated.
   * @param {ViewsuserconfigchangedEventdata} options Indicates to the service if custom options must be used.
   */
  reloadList(options: ViewsuserconfigchangedEventdata = null): void {
    this.data = null;
    this.configuration = null;
    this.listComponentService
        .refreshConfigurationAndLoadData(this.args, options)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response: ViewResult) => {
              this.data = this.listComponentService.data;
              this.listComponentService.detectChanges();
            },
            (error) => {
              console.error('Error in List "getConfiguration": ' + error);
            });
  }

  /**
   * Materialize the current list selection
   */
  materializeVboSelectionInMemory(): Observable<ViewsVboSelectedItem[]> {
    return this.listComponentService.materializeVboSelectionInMemory();
  }

  /**
   * Hide the title of the list
   */
  hideTitle(): void {
    this.listComponentService.getConfiguration().Title = null;
  }

  initializeDynamicComponent(params: any): void {
  }
}
