import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';

import {
  FormElementAutocompleteCallbackResult,
  FormElementOption,
  FormSubmitData
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { getInSafe, isNullOrUndefined, jsonEqual } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { EMPTY, throwError } from 'rxjs';

@Component({
  selector: 'app-acomplete',
  templateUrl: './acomplete.component.html',
  styleUrls: ['./acomplete.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AcompleteComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => AcompleteComponent), multi: true}
  ]
})
export class AcompleteComponent extends FrontendFormElementInput implements AfterViewInit, OnInit {

  _acValue: FormElementOption;

  @Output() empty = new EventEmitter<boolean>();

  @ViewChild('inputGroup') inputGroup;

  @ViewChild('listgroup') listgroup: ElementRef;

  optionsHeight: number;
  optionsWidth: number;
  optionList: FormElementOption[];
  searchValue: string;
  searched = false;
  searching = false;
  connectionError = false;

  private currentPage: number;
  private currentSearch: string;
  private isPaged: boolean;

  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected _eref: ElementRef,
              protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
  }

  ngOnInit(): void {
    this.optionsWidth = 100;
    this.optionList = [];
    this.currentPage = 0;
    this.searchValue = '';
    this.currentSearch = '';
    this.isPaged = false;
  }

  /**
   * Getter for the list-group styles of the control
   */
  get listGroupStyles(): Record<string, string> {
    return {
      height: this.optionsHeight ? this.optionsHeight + 'px' : 'auto',
      width: this.optionsWidth + 'px'
    };
  }

  /**
   * Getter for the _acValue
   * @returns {any}
   */
  get acValue(): FormElementOption {
    return this._acValue;
  }

  /**
   * Setter for the _acValue. On the setter the value is propagated
   * to let angular Forms know about this change
   * @param val: is the setter value
   */
  set acValue(val: FormElementOption) {
    this._acValue = val;
    this.propagateChange(val);
  }

  /**
   * This handler is called when the document click event
   * is fired
   *
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * @param e
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: any): void {
    if (!this._eref.nativeElement.contains(event.target) && this.searched === true) {
      this.clearSearch();
    }
  }

  /**
   * Clear search
   */
  clearSearch(): void {
    this.optionList = [];
    this.searched = false;
    this.forceDetectChanges();
  }

  /**
   * This function is used to communicate with the server and get
   * the options list
   * @param {string} search: search string. Is used in backend to
   * return the values that contains this string
   * @param page
   */
  protected getOptionList(search: string, page: number): void {

    // Evitar solapar búsquedas
    if (this.searching === true) {
      return;
    }

    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
    formSubmitData.submitElement = this.config.name;

    this.searching = true;
    this.connectionError = false;

    if (isNullOrUndefined(search)) {
      search = '';
    }

    this.formManagerService.getFieldautocompletecallback(
      formSubmitData,
      this.config.name,
      search,
      page
    )
      .pipe(
        takeUntil(this.componentDestroyed$),
        take(1),
        finalize(() => {
          this.searching = false;
        }),
        catchError((err) => {
          if (err.status === 0) {
            this.connectionError = true;
            this.searching = false;
            this.forceDetectChanges();
            return EMPTY;
          }
          return throwError(err);
        }),
      )
      .subscribe(
        (result: FormElementAutocompleteCallbackResult) => {

          this.searched = true;

          const resultOptions: FormElementOption[] = getInSafe(result, (i) => i.Options, []);

          if (page === 0) {
            this.isPaged = result.IsPaged;
            this.optionsWidth = this.inputGroup.nativeElement.offsetWidth;
          }

          this.optionList = [...this.optionList, ...resultOptions];

          // Update the max height of the element to force a scrollbar to be shown.
          this.forceDetectChanges();
          this.updateMaxHeight();
        }
      );
  }

  /**
   * Updates the max-height dynamically, depending upon number of elements to be shown
   */
  updateMaxHeight(): void {
    if (isNullOrUndefined(this.listgroup)) {
      return;
    }

    const element: any = this.listgroup.nativeElement;

    if (element.children && !element.children.length) {
      return;
    }

    const option: Element = (element.children as HTMLCollection)[0];
    const optionHeight: number = option.getBoundingClientRect().height;

    // Set the height of the options list to the nthElement height
    // This is to force the scrollbar to be shown.
    this.optionsHeight = element.children.length * optionHeight;

    // Triger a change detection cycle.
    this.forceDetectChanges();
  }


  /**
   * This function is called when input autocomplete element
   * get focus
   */
  inputTouched(): void {
    this.propagateTouch();
  }

  /**
   * Add a handler for events keydown.enter on components acomplete.
   *
   * Keydown is used because the forms trigger the submit in keydown.enter
   * events, the custom form components do not stop the propagation of
   * keydown.enter by default as do other native elements such as textareas,
   * empty input fields and others.
   * @see https://stackoverflow.com/questions/40909585/angular-2-how-to-prevent-a-form-from-submitting-on-keypress-enter
   * @see https://stackoverflow.com/questions/37362488/how-can-i-listen-for-keypress-event-on-the-whole-page
   * @param {KeyboardEvent} e Keyboard event
   */
  keydownHandler(e: KeyboardEvent): void {
    e.preventDefault();
  }

  /**
   * This method ask for new items if calback is paged
   */
  scrolledToEndHandler(): void {
    if (this.isPaged !== true || this.searching === true) {
      return;
    }
    this.currentPage++;
    this.getOptionList(this.currentSearch, this.currentPage);
  }

  /**
   * This function is used to trigger an options
   * search. The keyup.enter Event is used to know the current
   * value of the input
   * @param value
   * @param page
   */
  searchForResults(): void {
    this.optionList = [];
    this.currentPage = 0;
    this.currentSearch = this.searchValue;
    this.getOptionList(this.currentSearch, 0);
  }

  /**
   * This function is used to set the selected Option
   * @param {number} optionIndex
   */
  setOption(optionIndex: number): void {
    if (this.isDisabled) {
      return;
    }

    this.propagateTouch();
    this.empty.emit(false);
    this.acValue = this.optionList[optionIndex];
    this.optionList = [];
    this.forceDetectChanges();
  }

  /**
   * This function is used to set the selected option to null
   */
  unsetOption(): void {
    if (this.isDisabled) {
      return;
    }
    this.propagateTouch();
    this.empty.emit(true);
    this.acValue = null;
    this.forceDetectChanges();
  }

  /**
   * @inheritDoc
   */
  writeValue(value: FormElementOption): void {
    if (jsonEqual(value, this._acValue)) {
      return;
    }
    this._acValue = value;
    this.forceDetectChanges();
  }

  /**
   * @inheritDoc
   */
  equalValues(valueA: any, valueB: any): boolean {
    const valueA2: string = getInSafe(valueA as FormElementOption, (i) => i.Key, valueA);
    const valueB2: string = getInSafe(valueB as FormElementOption, (i) => i.Key, valueB);
    return valueA2 === valueB2;
  }
}
