import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractDecoupledModalComponent
} from '../../../../shared/decoupled-modal/models/abstract-decoupled-modal.component';
import { FuseNavigationService } from '../../../../../@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '../../../../../@fuse/types';
import { MenuType } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../../../../core/navigation/navigation.service';

@Component({
  selector: 'app-navigation-shortcuts',
  templateUrl: './navigation-shortcuts.component.html',
  styleUrls: ['./navigation-shortcuts.component.scss']
})
export class NavigationShortcutsComponent extends AbstractDecoupledModalComponent implements OnInit, AfterViewInit {

  public searchString: string;
  public navigation: FuseNavigationItem[] = [];
  public selectedItem: FuseNavigationItem;

  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  constructor(private _fuseNavigationService: FuseNavigationService,
              private _cdr: ChangeDetectorRef,
              private _router: NavigationService) {
    super();
    // this._cdr.detach();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Set focus on the input element
    Promise.resolve().then(() => this.searchInput.nativeElement.focus());
  }

  public onItemOver(event: MouseEvent, item: FuseNavigationItem): void {
    this.selectedItem = item;
  }

  public onKeyPressed(event: KeyboardEvent): void {
    let currentIndex: number = this.selectedItem ? this.navigation.indexOf(this.selectedItem) : -1;
    switch (event.key) {
      case 'ArrowDown':
        if (currentIndex === this.navigation.length - 1) {
          currentIndex = this.navigation.length - 2;
        }
        this.selectedItem = this.navigation[currentIndex + 1]
        break;
      case 'ArrowUp':
        if (currentIndex <= 0) {
          currentIndex = 1;
        }
        this.selectedItem = this.navigation[currentIndex - 1]
        break;
      case 'Enter':
        if (this.navigation.length === 1) {
          this._router.navigateByUrl(this.navigation[0].url).then(() => {
            this.closeModal();
          });
          return;
        }
        if (this.selectedItem) {
          this._router.navigateByUrl(this.selectedItem.url).then(() => {
            this.closeModal();
          });
        }
        break;
      default:
        this.selectedItem = null;
        this.refreshNavigation();
        break;
    }
    this._cdr.detectChanges();
  }

  public onLinkClick(item: FuseNavigationItem): void {
    if (item.url) {
      this._router.navigateByUrl(item.url).then(() => {
        this.closeModal();
      });
    }
  }

  public refreshNavigation(): void {
    if (!this.searchString || this.searchString.length === 0) {
      this.navigation = [];
      this.selectedItem = null;
      return;
    }

    const navigation: FuseNavigationItem[] = this._fuseNavigationService.getCurrentNavigation();

    if (!navigation) {
      this.navigation = [];
      this.selectedItem = null;
      return;
    }

    const items: FuseNavigationItem[] = [];

    this.filterNavigation(items, [...navigation], null, this.searchString);
    this.navigation = items;
  }

  private filterNavigation(items: FuseNavigationItem[], sourceItems: FuseNavigationItem[], parent: string, search: string): void {
    sourceItems.map((x: FuseNavigationItem, index: number) => {
      const item: FuseNavigationItem = {...x};

      switch (item.sabentisMenuItemCompiledFrontend?.menuType) {
        case MenuType.Anchor:
        case MenuType.Normal:
        default:
          const newTitle: string = parent && item.title ? parent + ' - ' + item.title : item.title;
          if (newTitle) {
            const parts: string = search.toLocaleLowerCase().split(' ').map(part => `(?=.*${part})`).join('');
            const regex: RegExp = new RegExp(parts, 'i');

            if (newTitle?.toLocaleLowerCase().match(regex) && item.url && !item.hidden) {
              item.title = newTitle;
              items.push(item);
            }
          }
          if (item.children && item.children.length > 0) {
            let titlePrefix: string = '';
            if (parent) {
              titlePrefix = parent;
              if (item.title) {
                titlePrefix += ' - ' + item.title;
              }
            } else if (item.title) {
              titlePrefix = item.title;
            }
            this.filterNavigation(items, [...item.children], titlePrefix, search);
          }
          break;
      }
    });
  }
}
